<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="项目详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="loading">
        <a-descriptions
          title="订单信息"
          :column="2"
          class="iotplt-descriptions iotplt-descriptions-max-6"
        >
          <a-descriptions-item label="客户名称">
            {{ data.agent_name }}
          </a-descriptions-item>

          <a-descriptions-item label="订单编号">
            {{ data.order_no }}
          </a-descriptions-item>

          <a-descriptions-item label="订单状态">
            {{ data.order_status }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          title="项目信息"
          :column="2"
          class="iotplt-descriptions iotplt-descriptions-max-6"
        >
          <a-descriptions-item label="项目编号">
            {{ data.item_no }}
          </a-descriptions-item>

          <a-descriptions-item label="项目状态">
            {{ data.status }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          title="采购详情"
          :column="2"
          class="iotplt-descriptions iotplt-descriptions-max-6"
        >
          <a-descriptions-item label="套餐名称">
            {{ data.product_name }}
          </a-descriptions-item>

          <a-descriptions-item label="套餐价格(元)">
            {{ data.distributor_price | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="运营商种类">
            {{ data.carrier_type }}
          </a-descriptions-item>

          <a-descriptions-item label="卡品">
            {{ data.card_type }}
          </a-descriptions-item>

          <a-descriptions-item label="用户业务模式">
            {{ data.user_business_type }}
          </a-descriptions-item>

          <a-descriptions-item label="卡板价格">
            {{ data.card_type_price }}
          </a-descriptions-item>

          <a-descriptions-item label="套餐周期">
            {{ data.service_cycle }}
          </a-descriptions-item>

          <a-descriptions-item label="采购卡数(张)">
            {{ data.card_count | formatBigNumber }}
          </a-descriptions-item>

          <a-descriptions-item label="套餐容量">
            {{ data.package_capacity }}
          </a-descriptions-item>

          <a-descriptions-item label="服务周期数">
            {{ data.service_period | formatBigNumber }}
          </a-descriptions-item>

          <a-descriptions-item label="语音时长">
            {{ data.voice_capacity }}
          </a-descriptions-item>

          <a-descriptions-item label="备注">
            {{ data.remark }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          title="项目金额"
          :column="2"
          class="iotplt-descriptions iotplt-descriptions-max-6"
        >
          <a-descriptions-item label="套餐费用(元)">
            {{ data.product_fee | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="卡板费用(元)">
            {{ data.card_fee | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="小计(元)">
            {{ data.total_fee | formatCurrency }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentPurchaseItem } from '@/api/agent_purchase_item'

export default {
  name: 'ShowAgentPurchaseItem',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentPurchaseItem(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
